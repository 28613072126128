.ant-drawer-left > .ant-drawer-content-wrapper {
  width: 300px !important;
}
.drawer .ant-drawer-body {
  background-color: var(--bgHome) !important;

}

.drawer_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.drawer_body .logo_box {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
}
.drawer_body .logo_box img {
  width: 200px !important;
  height: 35px;
}

.drawer_body .menu_item_box {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}

.drawer_body .menu_item_box ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.drawer_body .menu_item_box li {
  width: 100%;
  padding: 15px 20px;
  border-radius: 20px;
  cursor: pointer;
  color: var(--fontHome);

}
.drawer_body .menu_item_box li:hover {
  background-color: var(--primary);
}
.drawer_body .menu_item_box .active {
  background-color: var(--primary);
}

.drawer_body .button_box {
  width: 100%;
  display: flex;
  gap: 20px;
}
