.main_navbar {
  width: 100%;
  background-color: var(--bgWhite);
  height: 80px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.main_navbar .logo {
  width: 200px;
}

.main_navbar .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 33px;
}

.main_navbar .content ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.main_navbar .content ul li {
  color: var(--fontHome);
  cursor: pointer;
}
.main_navbar .content ul li:hover {
  color: var(--primary);
}
.main_navbar .content ul li.active {
  color: var(--primary) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.main_navbar .content .num_email {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.main_navbar .content .num_email p,
.main_navbar .content .num_email a {
  color: var(--primaryLight);
}

.main_navbar .content .buttons_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}


.toggleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  border: 3px solid #343434;
  border-radius: 20px;
  background: #343434;
  font-weight: bold;
  color: #343434;
  cursor: pointer;
}
.toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius: 20px;
  background: white;
  transition: all 0.3s;
}
.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}
.toggleContainer div {
  padding: 10px;
  text-align: center;
  z-index: 1;
}
.toggleCheckbox {
  display: none;
}
.toggleCheckbox:checked + .toggleContainer div:first-child {
  color: white;
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child {
  color: #343434;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child {
  color: #343434;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child {
  color: white;
  transition: color 0.3s;
}

@media (max-width: 1600px) {
  .main_navbar .content .num_email {
    display: none;
  }
}

@media (max-width: 930px) {
  .main_navbar .content ul,
  .main_navbar .content .buttons_box {
    display: none;
  }
  .main_navbar .toggleBTN{
    display: none;
  }
}

@media (min-width: 930px) {
  .menu_icon {
    display: none;
  }
}
