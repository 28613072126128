.home {
  background-color: var(--bgHome);
  color: var(--fontHome);
  width: 100%;
}
.home .downArrowBTN {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}
.home .hero_section {
  margin-block: 4rem;
}
.home .hero_section .heading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 54px;
  font-weight: 700;
  line-height: 76.8px;
  letter-spacing: -0.01em;
  text-align: left;
}
.home .hero_section .subHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}
.home .hero_section .leftIMG {
  width: 100%;
  height: auto;
}
.iconBg {
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 50%;
  color: #e9e9e9;
  width: fit-content;
  height: fit-content;
  margin-top: 1rem;
  cursor: pointer;
  text-align: center;
}
.home .divider {
  background-color: #e9e9e9 !important;
  margin: 4rem auto;
}
.home .screens_section .topText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.home .screens_section .topText .leftHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: left;
  width: 50%;
}
.home .screens_section .topText .rightHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  width: 50%;
}
.home .screens_section .bottomScreens {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  margin-top: 4rem;
}
.home .screens_section .bottomScreens .screensIMG {
  width: 520px;
  height: 290.8px;
  top: 1574.81px;
  left: 872px;
  gap: 0px;
}

.home .screens_section .bottomScreens .topScreensIMG {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 1rem;
}
.home {
  background-color: var(--bgHome);
  color: var(--fontHome);
  width: 100%;
}

.home .hero_section .heading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 54px;
  font-weight: 700;
  line-height: 76.8px;
  letter-spacing: -0.01em;
  text-align: left;
}
.home .hero_section .subHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.iconBg {
  background-color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  color: #e9e9e9;
  width: fit-content;
  height: fit-content;
  margin-top: 1rem;
  cursor: pointer;
  text-align: center;
}
.home .divider {
  background-color: var(--homeCardBG) !important;
  margin: 4rem auto;
}
.home .screens_section .topText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.home .screens_section .topText .leftHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: left;
  width: 50%;
}
.home .screens_section .topText .rightHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  width: 50%;
  opacity: 0.6;
}

/* .home .screens_section .bottomScreens .screensIMG {
  width: 520px;
  height: 290.8px;
  top: 1574.81px;
  left: 872px;
  gap: 0px;
  border-radius: 16px;
} */
.home .screens_section .bottomScreens .topScreensIMG {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.home .screens_section .bottomScreens .bottomScreensIMG {
  display: flex;
  align-items: end;
  justify-content: end;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.home .order_section {
  margin-top: 4rem;
  padding: 1rem;
}
.home .order_section p {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: start;
  margin-bottom: 1rem;
}
.home .order_section .order_images {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}
.home .order_section .order_images .orderIMG {
  width: 300px;
  height: 350px;
  gap: 0px;
  border-radius: 16px;
}
.home .cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  gap: 1rem;
}
.home .cards .card {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  padding: 20px;
  max-width: 48%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: var(--homeCardBG);
  color: var(--fontHome);
}
.home .cards .card:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  transform: scale(1.01);
  z-index: 1;
  box-sizing: border-box;
}
.home .cards .card .cardHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;
}
.home .cards .card .cardSubHeading {
  font-family: 'WhyteInktrapRegular', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  opacity: 0.6;
}
.home .mainHomeBTN {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.home .mobile_carousel {
  display: none;
}

@media (max-width: 768px) {
  /* .home .hero_section .leftIMG {
    width: unset  ;
    height: auto;
  } */
  .home .mobile_carousel {
    display: inline;
    margin: 2rem 0;
  }
  .home .order_section {
    display: none;
  }
  .home .screens_section .topText {
    flex-direction: column;
    gap: 1rem;
  }
  .home .screens_section .topText .leftHeading {
    width: 100%;
  }
  .home .screens_section .topText .rightHeading {
    width: 100%;
  }
  .home .cards .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
  .home .order_section p {
    font-size: 38px;
  }
}
@media (max-width: 550px) {
  .home .screens_section .bottomScreens .screensIMG {
    width: 100%;
    height: auto;
  }
  .home .hero_section .heading {
    font-size: 40px;
    line-height: 45px;
  }
  .home .screens_section .topText .leftHeading {
    font-size: 30px;
    line-height: 35px;
  }
  .home .cards .card .cardHeading {
    font-size: 24px;
    line-height: 36px;
  }
  .home .cards .card .cardSubHeading {
    font-size: 18px;
    line-height: 36px;
  }
  .home .divider {
    margin: 2rem auto;
  }
}

@media (max-width: 350px) {
  .home .cards {
    padding: 0;
  }
  .home .cards .card {
    min-width: 100%;
  }
  .home .order_section .order_images .orderIMG {
    width: 100%;
  }
}
