.sidebar-menu {
  background-color: transparent !important;
  color: var(--fontHome) !important;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-menu svg {
  font-size: 20px !important;
}

.menu-icon{
  color: var(--fontHome);
}
.ant-menu-item-selected{
  background-color: var(--subFont) !important;
  border-radius: 10px;
} 
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* border-right: 4px solid var(--primary) !important; */
  border-radius: 10px !important;
  width: 200px !important;
  /* width: calc(100% - 4px) !important; */
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected span,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected svg {
  color: var(--secondaryText) !important;
}
.menu_title{
  color: var(--subFont);
  text-align: left;
  padding: .5rem 2rem;
  font-size: 12px;
  font-weight: 400;
}
.menu_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80vh;
}
.menu_container .menu_items_top{
  width: 100%;
}
.menu_container .menu_items_bottom{
  width: 100%;
}

