.HowDoesWork .HowDoesWork_container {
  color: var(--fontHome);
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 16px;
}
.HowDoesWork .HowDoesWork_container h2 {
  /* font-family: ABC Whyte Inktrap Trial; */
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  text-align: left;
}
.HowDoesWork .HowDoesWork_cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 3rem 0;
}
.HowDoesWork .HowDoesWork_cards .card {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  padding: 20px;
  max-width: 25%;
  min-width: 150px;
  height: 340px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: var(--homeCardBG);
  color: var(--homeCardBG);
}
.HowDoesWork .HowDoesWork_cards .card .heading {
  /* font-family: ABC Whyte Inktrap Trial; */
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: left;
}
.HowDoesWork .HowDoesWork_cards .card .text {
  /* font-family: ABC Whyte Inktrap Trial; */
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  opacity: 0.8;
}
.HowDoesWork .HowDoesWork_cards .qrCodeCard {
  background-color: #256eff;
}
.HowDoesWork .HowDoesWork_cards .shoutoutCard {
  background-color: #0fff95;
  margin-top: -60px;
}
.HowDoesWork .HowDoesWork_cards .paymentCard {
  background-color: #f97068;
  margin-bottom: -80px;
}
.HowDoesWork .HowDoesWork_cards .goLiveCard {
  background-color: #ff00ff;
  margin-bottom: -120px;
}
@media (max-width: 1024px) {
  .HowDoesWork .HowDoesWork_cards .card {
    height: unset;
  }
  .HowDoesWork .HowDoesWork_cards .card .heading {
    font-size: 28px;
  }
  .HowDoesWork .HowDoesWork_cards .card .text {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  /* .HowDoesWork .HowDoesWork_cards {
    flex-direction: column;
  }
  .HowDoesWork .HowDoesWork_cards .card {
    flex: 1 1 45%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 1rem;
    padding: 20px;
    max-width: 48%;
    min-width: 300px;
  }
  .HowDoesWork .HowDoesWork_cards .shoutoutCard {
    margin-top: 0px;
  }
  .HowDoesWork .HowDoesWork_cards .paymentCard {
    margin-bottom: 0px;
  }
  .HowDoesWork .HowDoesWork_cards .goLiveCard {
    margin-bottom: 0px;
  } */
  .HowDoesWork .HowDoesWork_cards {
    flex-direction: row;
    flex-wrap: wrap; /* Allow cards to wrap into multiple rows */
    margin: 0;
  }
  
  .HowDoesWork .HowDoesWork_cards .card {
    flex: 1 1 calc(50% - 1rem); /* Adjust the width to fit 2 cards per row with some gap */
    max-width: calc(50% - 1rem); /* Ensure cards do not exceed half of the container width */
    min-width: 0; /* Allow flexibility in card width */
    margin: 0.5rem; /* Add margin to create spacing between cards */
  }
  
  .HowDoesWork .HowDoesWork_cards .shoutoutCard,
  .HowDoesWork .HowDoesWork_cards .paymentCard,
  .HowDoesWork .HowDoesWork_cards .goLiveCard {
    margin: 0; /* Remove margin adjustments for better alignment */
  }
  .HowDoesWork .HowDoesWork_container h2 {
    font-size: 32px;
  }
  .HowDoesWork .HowDoesWork_cards {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .HowDoesWork .HowDoesWork_cards {
    flex-direction: column;
    gap: 0;
  }

  .HowDoesWork .HowDoesWork_cards .card {
    flex: 1 1 100%; 
    max-width: 100%;
    min-width: 100%;
    margin-block: 10px;
    box-sizing: border-box;
  }

  .HowDoesWork .HowDoesWork_container h2 {
    font-size: 32px;
  }
}
