.terms_container {
    background-color: black;
}
.terms_container .terms_header {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.terms_container .terms_header h1 {
    font-size: 70px;
    font-weight: 500;
}
.terms_container .terms_content_container {
    font-size: 20px;
    padding: 10px;
}
.terms_container .terms_content_container ol li,
.terms_container .terms_content_container p,
.terms_container .terms_content_container h2 {
    padding-block: 20px;
    font-weight: 500;
}

.terms_container .terms_content_container ol li a {
    text-decoration: none;
    color: rgb(150, 147, 147);
    opacity: 0.9;
}

@media (max-width: 768px) {
    .terms_container .terms_header h1 {
        font-size: 50px;
    }
}

@media (max-width: 425px) {
    .terms_container .terms_header {
        height: 150px;
    }
    .terms_container .terms_header h1 {
        font-size: 40px;
        font-weight: 500;
    }
    .terms_container .terms_content_container ol li,
    .terms_container .terms_content_container p,
    .terms_container .terms_content_container h2 {
        font-size: 20px;
    }
}