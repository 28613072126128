.Footer {
  padding: 1rem 0;
}
.Footer .logo_copyright {
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.Footer .logo_copyright .copyright {
  /* font-family: ABC Whyte Inktrap Trial; */
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.Footer .logo_copyright .footerLogo {
  width: 200px !important;
}
.Footer .linkBoxes {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
.Footer .linkBoxes .linkBoxTop {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

.Footer .linkBoxes .linkBoxTop .linkBox {
  background-color: var(--homeCardBG);
  color: var(--fontHome);
  padding: 1rem;
  border-radius: 16px;
  width: 100%;
}
.Footer .linkBoxes .linkBoxTop .linkBox ul {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
.Footer .linkBoxes .linkBoxTop .linkBox ul li {
  /* font-family: ABC Whyte Inktrap Trial; */
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  cursor: pointer;
}
.Footer .linkBoxes .linkBoxBottom {
  background-color: var(--homeCardBG);
  color: var(--fontHome);
  padding: 1rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}
.Footer .linkBoxes .linkBoxBottom p {
  /* font-family: ABC Whyte Inktrap Trial; */
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.Footer .linkBoxes .linkBoxBottom .social_icons ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
}
.Footer .linkBoxes .linkBoxBottom .social_icons ul li {
  background-color: var(--bgHome);
  color: var(--fontHome);
  padding: 1rem;
  border-radius: 16px;
}
.Footer .copyrightMobile{
  display: none;
}
/* @media (max-width: 768px) {
  .Footer .linkBoxes .linkBoxTop {
    flex-direction: column;
}
} */
@media (max-width: 768px) {
  .Footer .linkBoxes .linkBoxTop .linkBox ul li {
    font-size: 14px;
    line-height: 20px;
  }
  .Footer .logo_copyright .copyright{
    display: none;
  }
  .Footer .linkBoxes .copyrightMobile{
    width: 100%;
    text-align: center;
  }
  .Footer .copyrightMobile{
    display: inline-block ;
  }
  
}
@media (max-width: 350px) {
  .Footer .linkBoxes .linkBoxTop .linkBox ul li {
    font-size: 12px;
  }
}
