.ant-menu-dark .ant-menu-sub {
  background: var(--sidebar);
}

.ant-layout-sider::-webkit-scrollbar {
  display: none;
}
.ant-switch-checked {
  background-color: var(--primary) !important;
}
.ant-switch-handle {
  top: 3px !important;
  width: 15px !important;
  height: 15px !important;
  left: 7px;
}
.ant-switch-checked .ant-switch-inner {
  margin-left: 3px !important;
}
.ant-drawer-content-wrapper {
  width: 100% !important;
}
.v-layout .company-logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
  color: var(--text);
}
.v-layout .company-logo img {
  width: 70%;
}
.v-layout .company-title {
  font-size: 22px;
  color: var(--text) !important;
  text-transform: uppercase;
}
.headerRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
}
.headerRight .path {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.headerRight .path .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-transform: capitalize;
}
.headerRight .path .subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}
.headerRight .iconBG {
  background: #f1f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  color: #1f2937;
}
.headerRight .user_profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.headerRight .user_profile .user_info p:first-child {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: right;
}
.headerRight .user_profile .user_info p:last-child {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  color: #848484;
}

/* .logout {
  margin: -2rem 1rem !important;
} */
.logout .ant-menu-item {
  background-color: var(--bgHome) !important;
  color: var(--fontHome) !important;
}
