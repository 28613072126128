@font-face {
  font-family: "WhyteInktrapBlack";
  src: url("../assets/fonts/WhyteInktrap-Black.woff") format("woff"),
    url("../assets/fonts/WhyteInktrap-Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "WhyteInktrapRegular";
  src: url("../assets/fonts/WhyteInktrap-Regular.woff") format("woff"),
    url("../assets/fonts/WhyteInktrap-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: WhyteInktrapRegular, "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background-color: #eaeaea;
}

.swal-text {
  text-align: center !important;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 0px;
  margin: 0;
}
.empty {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.m-20 {
  margin: 20px 0;
}
.iconBGSquare {
  background: #ff495b1e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 20%;
  color: #1f2937;
  width: fit-content;
}
/* .top_padding_table {
  padding-top: 2rem;
} */

.percentage_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  gap: 0.5rem;
}
.percentage_box p {
  color: var(--menuicon);
}
.dashboard_container .analytic_box .label {
  color: var(--menuicon);
}
.percentage_box p:nth-child(2) {
  border-radius: 20px;
  padding: 3px;
  background: #3affcf;
  font-weight: 500;
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.ant-upload-list {
  display: flex;
  flex-direction: column-reverse;
}

.btnHome {
  background-color: var(--primary);
  color: var(--bgHome);
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.center_element {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.layoutPaddingInline {
  padding-inline: 100px;
}

.primaryBTN {
  /* font-family: ABC Whyte Inktrap Trial; */
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: center;
  color: var(--bgHome);
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 16px;
  cursor: pointer;
  border: none;
  width: 100%;
}
.Inline_Padding_Table {
  padding: 0 1rem;
}
.error_notification {
  background-color: var(--homeCardBG) !important;
}
.error_notification .ant-notification-notice-message {
  color: var(--fontHome) !important;
}
.error_notification .ant-notification-notice-description {
  color: var(--fontHome) !important;
}
.error_notification .ant-notification-notice-close {
  color: var(--fontHome) !important;
}
.ant-input-outlined:focus {
  border-color: var(--primary) !important;
}
.ant-input-outlined:hover {
  border-color: var(--primary) !important;
}
.ant-select-focused:where(
    .css-dev-only-do-not-override-1omp2n
  ).ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: var(--primary) !important;
}
.ant-input-outlined:hover{
  border-color: var(--primary) !important;
}
@media (max-width: 1780px) {
  .layoutPaddingInline {
    padding-inline: 80px;
  }
}
@media (max-width: 1440px) {
  .layoutPaddingInline {
    padding-inline: 60px;
  }
}
@media(max-width: 800px) {
  .ant-layout-content{
    padding-inline: 10px;
  }
}
@media (max-width: 550px) {
  .layoutPaddingInline {
    padding-inline: 20px;
  }
  .btnHome {
    font-size: 14px;
    font-weight: 600;
  }
}
